import React from 'react'
import Portofolio from "@views/Portofolio";
import Layout from "@components/Layout";

export default function portofolio() {
  return (
    <Layout noFooter>
      <Portofolio />
    </Layout>
  )
}
