export const ITEM_PER_PAGE = 8;

export interface CarListProps {
  img: string;
  title: string;
  to: string;
}

export const CAR_LIST: CarListProps[] = [
  { img: "/images/home/car-cards/ekonomis.png", title: "EKONOMIS", to: "/" },
  {
    img: "/images/home/car-cards/ekonomis-plus.jpg",
    title: "EKONOMIS PLUS",
    to: "/",
  },
  { img: "/images/home/car-cards/standar.jpg", title: "STANDAR", to: "/" },
  {
    img: "/images/home/car-cards/vip.jpg",
    title: "VIP / DELUXE INTERNATIONAL",
    to: "/",
  },
  {
    img: "/images/home/car-cards/mobil-jenazah.jpg",
    title: "MOBIL JENAZAH",
    to: "/",
  },
  {
    img: "/images/home/car-cards/mobil-perpustakaan.jpg",
    title: "MOBIL PERPUSTAKAAN",
    to: "/",
  },
  {
    img: "/images/home/car-cards/branding.jpg",
    title: "BRANDING & MOBIL PARTAI",
    to: "/",
  },
  {
    img: "/images/home/car-cards/aksesoris.jpg",
    title: "AKSESORIS AMBULANCE",
    to: "/",
  },
  {
    img: "/images/home/car-cards/sparepart.jpg",
    title: "PENGADAAN SPAREPART",
    to: "/",
  },
  { img: "/images/home/car-cards/campervan.jpg", title: "CAMPERVAN", to: "/" },
  { img: "/images/home/car-cards/foodtruck.jpg", title: "FOODTRUCK", to: "/" },
];

export interface CarTypeProps {
  text: string;
  to: string;
}

export const CAR_TYPES: CarTypeProps[] = [
  { text: "DAIHATSU", to: "/" },
  { text: "SUZUKI", to: "/" },
  { text: "ISUZU", to: "/" },
  { text: "TOYOTA", to: "/" },
  { text: "HYUNDAI", to: "/" },
  { text: "WULLING", to: "/" },
  { text: "MITSHUBISHI", to: "/" },
  { text: "DAN LAIN-LAIN", to: "/" },
];

export interface OurAdvantagesProps {
  icon: string;
  textHtml: string;
}

export const OUR_ADVANTAGES: OurAdvantagesProps[] = [
  {
    icon: "/images/home/keunggulan/produk.png",
    textHtml: "PRODUKSI<br />CEPAT",
  },
  { icon: "/images/home/keunggulan/bergaransi.png", textHtml: "BERAGARANSI" },
  {
    icon: "/images/home/keunggulan/pelayanan.png",
    textHtml: "PELAYANAN<br />ONLINE 24 JAM",
  },
  {
    icon: "/images/home/keunggulan/konsultasi.png",
    textHtml: "KONSULTASI<br />GRATIS",
  },
];

export interface OurLocationProps {
  title: string;
  embedLink: string;
  address: string;
  btnLink: string;
}

export const OUR_LOCATION: OurLocationProps[] = [
  {
    title: "HEAD OFFICE",
    address:
      "Jl. Pitara Rw. 04 Rt.15 No. 08 Kel. PancoranMas, Kec. Pancoran Mas Kota Depok, Jawa Barat",
    btnLink: "https://goo.gl/maps/V7AMzFP8mTpySSCa9",
    embedLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.890976698185!2d106.80200526865526!3d-6.408043382082559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e967788b468b%3A0xe2a2c6a963c7e166!2sJl.%20Pitara%2C%20Kota%20Depok%2C%20Jawa%20Barat!5e0!3m2!1sid!2sid!4v1647074471349!5m2!1sid!2sid",
  },
  {
    title: "WORKSHOPS",
    address:
      "Depok : Jl. Pitara Raya No 20 - 56 Pancoran Mas Depok Jawa Barat  16435",
    btnLink: "https://goo.gl/maps/m8HxNc4v8ZQMasSs5",
    embedLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.880781147175!2d106.79912611439298!3d-6.409355095362586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e96e8de8e135%3A0x68b73a6f1803f0c0!2sJl.%20Pitara%20No.20%2C%20Pancoran%20MAS%2C%20Kec.%20Pancoran%20Mas%2C%20Kota%20Depok%2C%20Jawa%20Barat%2016436!5e0!3m2!1sid!2sid!4v1647075856735!5m2!1sid!2sid",
  },
];

export interface OurLocationProps {
  title: string;
  embedLink: string;
  address: string;
  btnLink: string;
}

export interface OurVideoProps {
  title: string;
  embedLink: string;
}

export const OUR_VIDEO: OurVideoProps[] = [
  {
    title: "Tutorial Lampu Sirine Ambulance",
    embedLink: "https://www.youtube.com/embed/mYEJB3pNoAo",
  },
  {
    title: "Campervan VW Transporter",
    embedLink: "https://www.youtube.com/embed/Bdm9yTex7hU",
  },
  {
    title: "Tutorial Tenda Awning Otomatis",
    embedLink: "https://www.youtube.com/embed/mjt8FegHbqU",
  },
  {
    title: "Mobil Branding Partai / Mobil Caleg",
    embedLink: "https://www.youtube.com/embed/qepvtXE1QQ4",
  },
];
