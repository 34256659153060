import React, { useState } from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row } from "react-bootstrap";
import Galleries from "./Sections/Galleries";
import { ITEM_PER_PAGE } from "@utils/static-data";

const query = graphql`
  query PortofolioQuery {
    portofolio: allFile(
      filter: { relativeDirectory: { regex: "/(portofolio)/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`;



export default function Portofolio() {
  const { portofolio } = useStaticQuery(query);
  const [page, setPage] = useState<number>(0);

  const onClick = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const PaginateProps: ReactPaginateProps = {
    previousLabel: "PREV",
    nextLabel: "NEXT",
    breakLabel: "...",
    containerClassName: "paginate-container",
    breakClassName: "paginate-break",
    pageClassName: "paginate-page",
    pageCount: Math.ceil(portofolio.edges.length / ITEM_PER_PAGE),
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 3,
    onPageChange: onClick,
    activeClassName: "active",
  };



  return (
    <div>
      <section id="portofolio">
        <Container>
          <h1 className="text-secondary my-4">PORTFOLIO KAMI</h1>
          <Row>
            <Galleries page={page} portofolio={portofolio} />
          </Row>
          <ReactPaginate {...PaginateProps} />
        </Container>
      </section>
    </div>
  );
}
