import React from "react";
import { Col } from "react-bootstrap";
import Img from "gatsby-image";
import { IEdge } from "src/types";
import { ITEM_PER_PAGE } from "@utils/static-data";

interface PortofolioProps {
  page: number;
  portofolio: {
    edges: IEdge[];
  };
}

export default function Galleries(props: PortofolioProps) {
  const { edges } = props.portofolio;
  const firstIndex = props.page * ITEM_PER_PAGE;

  let newEdges: IEdge[] = [];
  if (edges.length > 0) {
    newEdges = [...edges].slice(firstIndex, firstIndex + ITEM_PER_PAGE);
  }

  return (
    <React.Fragment>
      {newEdges.map(({ node }) => (
        <Col xs={6} md={3} className="my-3">
          <Img fluid={node.childImageSharp.fluid} alt="porto" />
        </Col>
      ))}
    </React.Fragment>
  );
}
